import React from 'react';
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <footer>
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <p className="copyright">©2023 <strong>Veerus Holdings LLC</strong>. All Rights Reserved.</p>
                </div>
                <div className="col-md-2">
                    <ul className="list-unstyled">
                        <li><Link to="/">Log In</Link></li>
                    </ul>
                </div>
                <div className="col-md-2">
                    <ul className="list-unstyled">
                        <li><Link to="/">Contact Us</Link></li>
                        <li><Link to="/">Terms of Use</Link></li>
                        <li><Link to="/">Privacy Policy</Link></li>
                        <li><Link to="/">CCPA</Link></li>
                    </ul>
                </div>
                
                <div className="col-md-2 powered-by">
                    <p>Powered by</p>
                    {/* <h4><Link to="https://www.jangl.com" target="_blank">Jangl</Link></h4> */}
                </div>
                
                
            </div>
        </div>
    </footer>
    )
}

export default Footer;
