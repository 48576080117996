
import React from 'react';
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import AuthStatus from '../auth/AuthStatus';
import Header from '../component/Header';
const Layout = () => {
    return (
        <>
           <Header />
            <div>
                <Outlet />
            </div>
        </>

    );
}

export default Layout;