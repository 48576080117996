import React from 'react';
import Header from "../component/Header";
import Footer from "../component/Footer";

export default function Not_found() {
  return (
    <>
      <Header />
      <div className="body">
                <div className="container page-container">
                    <div className="content">
                    {/* <h2 className='no-record'>Page Not Found !!</h2> */}
        </div>
        </div>
        </div>
      <Footer />
    </>
  )
}
