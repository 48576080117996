import React from 'react';
import Header from "../component/Header";
import Footer from "../component/Footer";

export default function Leanding_page() {
  return (
    <>
      <Header />
      <div class="body">
     <h1>Home</h1>
        </div>
      <Footer />
    </>
  )
}
