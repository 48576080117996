import React from 'react';

const PageNotFound = () => {
    return (
        <div>
            <h1>Opps !</h1>
            <h3>Page not found</h3>
        </div>
    );
}

export default PageNotFound;
