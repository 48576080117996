import React from 'react';
import { fakeAuthProvider } from "./fakeAuthProvider";
import { UserContext } from '../context/UserContext';

const AuthProvider = ({ children }) => {

    let [user, setUser] = React.useState(null);

    let signin = (newUser, callback) => {
        return fakeAuthProvider.signin(() => {
            setUser(newUser);
            callback();
        });
    };

    let signout = (callback) => {
        return fakeAuthProvider.signout(() => {
            setUser(null);
            callback();
        });
    };

    let value = { user, signin, signout };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;

}

export default AuthProvider;
