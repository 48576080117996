import React from 'react';
import { Link } from 'react-router-dom';


const Header = () => {
    return (
        <header>
            <div className="container">
                <div className="d-flex align-items-center justify-content-between pt-4">
                    <h1><Link to="/"><img src="./image/verilead-single.png" alt=''/></Link></h1>
                    <h4 className="text-right">Prepared for: VLT</h4>
                </div>
            </div>
        </header>
    )
}

export default Header;
