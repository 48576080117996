import React from 'react';

const SpinnerOfDoom = () => {
    return (
        <div>
            loading
        </div>
    );
}

export default SpinnerOfDoom;
