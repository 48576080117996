import React from 'react';
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import useAuth from '../customHooks/useAuth';
const AuthStatus = () => {
    let auth = useAuth();
    let navigate = useNavigate();
  
    if (!auth.user) {
      return <Link to={'/login'}>Login</Link>
    }
  
    return (
      <p>
        Welcome {auth.user}!{" "}
        <button
          onClick={() => {
            auth.signout(() => navigate("/"));
          }}
        >
          Sign out
        </button>
      </p>
    );
}

export default AuthStatus;
