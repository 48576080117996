import logo from './logo.svg';
// import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  BrowserRouter,
} from "react-router-dom";
import React from 'react';
import SpinnerOfDoom from './component/SpinnerOfDoom';
import PageNotFound from './component/PageNotFound';

// import Contact from './pages/Contact';

import  Home  from "./pages/Home";
import Login from './pages/Login';

import Layout from './Layouts/Layout';
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import AuthProvider from './auth/AuthProvider';
import RequireAuth from './auth/RequireAuth';
import PublicPage from './pages/PublicPage';
import ProtectedPage from './pages/ProtectedPage';
import Not_found from './pages/Not_found';
import Leanding_page from './pages/Leanding_page';

const Contact = React.lazy(() => import("./pages/Contact"));

// function useAuth() {
//   return React.useContext(AuthContext);
// }

function App() {
  
  let AuthContext = React.createContext(null);

  return (
    <AuthProvider>
        <Routes >
            <Route path="/login" element={<Login />} />
            <Route element={<Layout />}>
              <Route path="/:vendorCampaignId" element={<Home />} />
              <Route path="/gggg" element={<Leanding_page />} />
              <Route path="/contact" element={ <React.Suspense fallback={<>Loading</>}> <RequireAuth> <Contact /> </RequireAuth> </React.Suspense>} />
            </Route>
         
            <Route path="*" element={<Not_found />} />
        </Routes>
    </AuthProvider>
  );
}

export default App;

