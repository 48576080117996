import React, { useEffect, useState } from "react";
import Footer from "../component/Footer";
import { useParams } from "react-router-dom";
import axios from "axios";

const Home = () => {
  const [apiData, setApiData] = useState({});
  const [status, setStatus] = useState(false);
  const { vendorCampaignId } = useParams();


  const dataNoBid = {
    code: 200,
    message: "success",
    payout:0,
    responseType:"NOBID"
  };


  const data = {
    status: "denied",
    errors: {
      meta: {
        lead_id_code: ["This field may not be blank."],
        landing_page_url: ["Enter a valid URL."],
      },
    },
  };

  const data1 = {
    code: 200,
    message: "Access Denied Error: No buyer campaigns found for the vendor campaign 565525d2-0bd2-434a-9eeb-feba793b4d4b",
  };
  const data2 = {
    meta: {
      originally_created: "2015-09-15T14:12:00Z",
      source_id: "1A2B3C4D",
      offer_id: "13502",
      lead_id_code: "4xyz78b9-0cdc-43a7-98ea-2b680a5313a2",
      trusted_form_cert_url: "https://cert.trustedform.com/f886071...",
      user_agent:
        "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/51.0.2704.103 Safari/537.36",
      landing_page_url: "https://www.somewebsite.com",
      tcpa_compliant: true,
      tcpa_consent_text:
        "I agree to receive to be contacted by phone or email.",
    },
    contact: {
      phone_last_four: "0001",
      zip_code: "10010",
      ip_address: "10.0.0.1",
    },
    data: {
      height: 70,
      weight: 180,
      birth_date: "1980-05-15",
      gender: "M",
      student: false,
      tobacco: false,
      bmi: 19,
      medical_condition: "Cancer",
      spouse: {
        height: 61,
        weight: 115,
        birth_date: "1970-01-01",
        gender: "F",
        student: false,
        tobacco: true,
        medical_condition: "None",
      },
      children: [
        {
          height: 60,
          weight: 90,
          birth_date: "1997-01-01",
          gender: "F",
          student: false,
          tobacco: true,
          medical_condition: "Asthma",
        },
        {
          height: 60,
          weight: 90,
          birth_date: "1997-01-01",
          gender: "F",
          student: false,
          tobacco: true,
          medical_condition: "Asthma",
        },
      ],
      currently_employed: true,
      number_in_household: 4,
      household_income: 60000,
      hospitalized: false,
      ongoing_medical_treatment: true,
      previously_denied: true,
      prescriptions: true,
      prescription_description: "Currently using Aerospan for Asthma",
      qualifying_life_condition: "Married Or Divorced",
      current_policy: {
        insurance_company: "MetLife",
        expiration_date: "2017-06-01",
        insured_since: "2015-06-01",
      },
    },
  };

  const data3 = {
    auth_code: "DUWlqMFRXclBzZ2s9X18tMTEyNDk3MjE3Mg",
    meta: {
      originally_created: "2015-09-15T14:12:00Z",
      source_id: "1A2B3C4D",
      offer_id: "13502",
      lead_id_code: "4xyz78b9-0cdc-43a7-98ea-2b680a5313a2",
      trusted_form_cert_url: "https://cert.trustedform.com/f886071...",
      user_agent:
        "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/51.0.2704.103 Safari/537.36",
      landing_page_url: "https://www.somewebsite.com",
      tcpa_compliant: true,
      tcpa_consent_text:
        "I agree to receive to be contacted by phone or email.",
    },
    contact: {
      first_name: "John",
      last_name: "Doe",
      email: "test@email.com",
      phone: "2123456789",
      address: "123 Main St. #12A",
      city: "New York",
      state: "NY",
      zip_code: "10010",
      ip_address: "10.0.0.1",
    },
    data: {
      height: 70,
      weight: 180,
      birth_date: "1980-05-15",
      gender: "M",
      student: false,
      tobacco: false,
      bmi: 19,
      medical_condition: "Cancer",
      spouse: {
        height: 61,
        weight: 115,
        birth_date: "1970-01-01",
        gender: "F",
        student: false,
        tobacco: true,
        medical_condition: "None",
      },
      children: [
        {
          height: 60,
          weight: 90,
          birth_date: "1997-01-01",
          gender: "F",
          student: false,
          tobacco: true,
          medical_condition: "Asthma",
        },
        {
          height: 60,
          weight: 90,
          birth_date: "1997-01-01",
          gender: "F",
          student: false,
          tobacco: true,
          medical_condition: "Asthma",
        },
      ],
      currently_employed: true,
      number_in_household: 4,
      household_income: 60000,
      hospitalized: false,
      ongoing_medical_treatment: true,
      previously_denied: true,
      prescriptions: true,
      prescription_description: "Currently using Aerospan for Asthma",
      qualifying_life_condition: "Married Or Divorced",
      current_policy: {
        insurance_company: "MetLife",
        expiration_date: "2017-06-01",
        insured_since: "2015-06-01",
      },
    },
  };
  const data4 = {
    meta: {
      originally_created: "2015-09-15T14:12:00Z",
      source_id: "1A2B3C4D",
      offer_id: "13502",
      lead_id_code: "4xyz78b9-0cdc-43a7-98ea-2b680a5313a2",
      trusted_form_cert_url: "https://cert.trustedform.com/f886071...",
      user_agent:
        "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/51.0.2704.103 Safari/537.36",
      landing_page_url: "https://www.somewebsite.com",
      tcpa_compliant: true,
      tcpa_consent_text:
        "I agree to receive to be contacted by phone or email.",
    },
    contact: {
      first_name: "John",
      last_name: "Doe",
      email: "test@email.com",
      phone: "2123456789",
      address: "123 Main St. #12A",
      city: "New York",
      state: "NY",
      zip_code: "10010",
      ip_address: "10.0.0.1",
    },
    data: {
      height: 70,
      weight: 180,
      birth_date: "1980-05-15",
      gender: "M",
      student: false,
      tobacco: false,
      bmi: 19,
      medical_condition: "Cancer",
      spouse: {
        height: 61,
        weight: 115,
        birth_date: "1970-01-01",
        gender: "F",
        student: false,
        tobacco: true,
        medical_condition: "None",
      },
      children: [
        {
          height: 60,
          weight: 90,
          birth_date: "1997-01-01",
          gender: "F",
          student: false,
          tobacco: true,
          medical_condition: "Asthma",
        },
        {
          height: 60,
          weight: 90,
          birth_date: "1997-01-01",
          gender: "F",
          student: false,
          tobacco: true,
          medical_condition: "Asthma",
        },
      ],
      currently_employed: true,
      number_in_household: 4,
      household_income: 60000,
      hospitalized: false,
      ongoing_medical_treatment: true,
      previously_denied: true,
      prescriptions: true,
      prescription_description: "Currently using Aerospan for Asthma",
      qualifying_life_condition: "Married Or Divorced",
      current_policy: {
        insurance_company: "MetLife",
        expiration_date: "2017-06-01",
        insured_since: "2015-06-01",
      },
    },
  };
  const data5 = {
    code: 200,
    message:"success",
    payout: 18.0,
    ping: "DUWlqMFRXclBzZ2s9X18tMTEyNDk3MjE3Mg==",
    responseType:"BID"
  };
  const data6 = {
    code:200,
    message: "success",
    responseType:"POST Success",
    price: 18.0
  };
  const data7 = {
    status: "re-ping",
  };
  const data8 = {
    status: "denied",
    errors: {
      meta: {
        lead_id_code: ["This field may not be blank."],
        landing_page_url: ["Enter a valid URL."],
      },
    },
  };

  const data9 = {
    code: 500,
    message: "Error: Ping for _leadid \"65be09aa-a010-4cb6-b68f-a8326026eeb\" was not found"
  };

  const fetchdata = async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${vendorCampaignId}`
      );
      if (response?.data?.responseData) {
        setApiData(response?.data?.responseData);
        setStatus(true);
        console.log("hh");
      } else {
        setStatus(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchdata();
  }, [vendorCampaignId]);

  return (
    <>
      <div className="body bg_blue">
        <div className="container page-container">
          <div className="content">
            {
              status ? (
                <div className="markdown">
                  <h1 className="text-center header-text">
                    {apiData?.vendorCampaignName} API v2 - Integration Specs
                  </h1>
                  <hr />
                  <h2 id="pingpost-overview" className="testing-text">
                    Ping/Post Overview
                  </h2>
<ol>
  <li>
    <b>Ping Endpoint Setup:</b>
    <ul>
      <li>Set the ping endpoint and configure the environment variables as specified, along with the requested variables.</li>
    </ul>
  </li>
  
  <li>
    <b>Additional Fields:</b>
    <ul>
      <li>The system requires two additional fields:</li>
      <ul>
        <li><code>lead_id</code></li>
        <li><code>tcpa_url</code></li>
      </ul>
      <li>The <code>lead_id</code> will be validated using Jornaya.</li>
    </ul>
  </li>
  
  <li>
    <b>Ping Request Submission:</b>
    <ul>
      <li>After submitting the ping request, the following outcomes may occur:</li>
      <ul>
        <li>
          Validation Error:
          <ul>
            <li>If <code>lead_id</code> or <code>tcpa_url</code> are not provided, the system will return a validation error. The error message will be self-explanatory.</li>
          </ul>
        </li>
        <li>
          Validation Pass:
          <ul>
            <li>Upon successful validation, the lead will be entered into our system, but no buyer campaign will be attached initially.</li>
            <li>You will receive a sample response (example provided below).</li>
          </ul>
        </li>
      </ul>
    </ul>
  </li>
  
  <li>
    <b>Re-ping Process:</b>
    <ul>
      <li>You can re-ping using the same request if a buyer campaign is attached but no buyers place a bid. In this case, you will receive a <code>NOBID</code> response (see sample below).</li>
      <li>If a buyer responds with a bid, you can re-ping again using the same call. You will receive a successful <code>BID</code> response, along with the lead ID under the ping section (example provided below). This lead ID is needed for the next step.</li>
    </ul>
  </li>
  
  <li>
    <b>POST Call:</b>
    <ul>
      <li>After receiving the lead ID from the ping request, make a POST call to our POST endpoint.</li>
      <li>Include an additional field:</li>
      <ul>
        <li><code>"_leadid": “&lt;value from ping call&gt;”</code></li>
      </ul>
      <li>Once the POST call is successful, you will receive the price details in the response.</li>
    </ul>
  </li>
</ol>


                  {apiData?.data?.urls != undefined &&
                    apiData?.data?.urls.length > 0 &&
                    apiData?.data?.urls.map((val, i) => (
                      <div key={i}>
                        <h3 id="testing-urls">{val.envDisplayName} URLs</h3>
                        <p>
                          <strong className="testing-text">Ping</strong>
                        </p>
                        <pre>
                          <code>{val?.pingURL}</code>
                        </pre>
                        <p>
                          <strong className="testing-text">Post</strong>
                        </p>
                        <pre>
                          <code>{val?.postURL}</code>
                        </pre>
                      </div>
                    ))}

                  {/* <h3 id="production-urls">Production URLs</h3>

                            <p><strong>Ping</strong></p>

                            <pre><code>{process.env.REACT_APP_PRODUCTION_ENDPOINT}ping
                            </code></pre>

                            <p><strong>Post</strong></p>

                            <pre><code>{process.env.REACT_APP_PRODUCTION_ENDPOINT}post
                            </code></pre>

                            <p><strong>Direct Post</strong></p>

                            <pre><code>{process.env.REACT_APP_PRODUCTION_ENDPOINT}direct_post
                            </code></pre> */}

                  <h3 id="request-headers" className="testing-text">
                    Request Headers
                  </h3>

                  <p>
                    You must use HTTP headers for authorization and formatting.
                    The <strong>Authorization</strong> header is{" "}
                    <strong>required</strong> and must contain your API key
                    prefixed by the string literal <strong>“Token”</strong>,
                    with whitespace separating the two strings. Example:
                  </p>

                  <pre>
                    <code>
                      Authorization: {apiData?.data?.requestHeaders?.authToken}
                    </code>
                  </pre>

                  <p>
                    The request body may be formatted either as JSON (default)
                    or XML. The API will determine how to parse the request by
                    reading the <strong>Content-Type</strong> header. The two
                    valid options are:
                  </p>

                  <pre>
                    <code>
                      {apiData?.data?.requestHeaders?.requestContentType}
                    </code>
                  </pre>

                  <p>
                    The response will be formatted either as JSON (default) or
                    XML. The API will determine how to render the response by
                    reading the <strong>Accept</strong> header. The two valid
                    options are:
                  </p>

                  <pre>
                    <code>
                      {apiData?.data?.requestHeaders?.responseContentType}
                    </code>
                  </pre>
                  {apiData?.data?.variables != undefined &&
                    apiData?.data?.variables.length > 0 &&
                    apiData?.data?.variables.map((value, key) => (
                      <div key={key}>
                        <h3 id="request-variables" className="testing-text">
                          {value?.variableDisplayName} Variables
                        </h3>
                        <div className="table-responsive">
                          <table>
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Data Type</th>
                                <th>Required/ Optional</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {value?.properties != undefined &&
                                value?.properties.length > 0 &&
                                value?.properties.map((val, i) => (
                                  <tr key={i}>
                                    <td>
                                      <strong>{val?.label}</strong>
                                    </td>
                                    <td>{val?.type}</td>
                                    <td>{val?.required ? "true" : "false"}</td>
                                    <td></td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ))}
                  {/* <h2 id="ping-post-example-requests">Ping / Post Example Requests</h2>
                            <h3 id="json-ping-request">JSON Ping Request</h3>
                            <pre><code>
                                POST /v2/health_insurance/ping HTTP/1.1<br></br>
                                Host: api.jangl.com<br></br>
                                Authorization: {apiData?.data?.requestHeaders?.authToken} <br></br>
                                Content-Type: application/json<br></br>
                                Accept: application/json<br></br><br></br>
                                {JSON.stringify(data2, null, 2)}

                            </code>
                            </pre> */}

                  {/* <h3 id="json-post-request">JSON Post Request</h3>

                            <pre><code>POST /v2/health_insurance/post HTTP/1.1<br></br>
                                Host: api.jangl.com<br></br>
                                Authorization:{apiData?.data?.requestHeaders?.authToken} <br></br>
                                Content-Type: application/json<br></br>
                                Accept: application/json<br></br><br></br>
                                {JSON.stringify(data3, null, 2)}
                            </code></pre> */}

                  {/* <h2 id="direct-post-example-requests">Direct Post Example Requests</h2>

                            <h3 id="json-direct-post-request">JSON Direct Post Request</h3>

                            <pre><code>POST /v2/health_insurance/direct_post HTTP/1.1<br></br>
                                Host: api.jangl.com<br></br>
                                Authorization: {apiData?.data?.requestHeaders?.authToken} <br></br>
                                Content-Type: application/json<br></br>
                                Accept: application/json<br></br><br></br>
                                {JSON.stringify(data4, null, 2)}

                            </code></pre> */}

                  <h2 id="ping-responses">Ping Responses</h2>

                  <h3 id="json-success">JSON - Success</h3>

                  <pre>
                    <code>{JSON.stringify(data5, null, 2)}</code>
                  </pre>

                  <h3 id="json-denied">JSON - No Active Buyer</h3>

<pre>
  <code>{JSON.stringify(dataNoBid, null, 2)}</code>
</pre>

                  <h3 id="json-denied">JSON - Denied</h3>

                  <pre>
                    <code>{JSON.stringify(data1, null, 2)}</code>
                  </pre>

                  <h3 id="xml-success">XML - Success</h3>

                  <pre>
                    <code>
                      <p>{"<PingResponse>"} </p>
                      <p> {"<code>200</code>"}</p>
                      <p>{"<message>success</message>"}</p>
                      <p> {"<payout>18.00</payout>"}</p>
                      <p>
                        {" "}
                        {
                          "<ping>713e4818-849f-43b5-9897-58762b07dc60</ping> "
                        }
                      </p>
                      <p>{"<responseType>BID</responseType>"}</p>
                      <p>{"</PingResponse>"} </p>
                    </code>
                  </pre>

                  <h3 id="xml-denied">XML - No Active Buyer</h3>
                  <pre>
                    <code>
                      <p>{'<?xml version="1.0" encoding="utf-8"?> '}</p>
                      <p>{"<PingResponse>"}</p>
                      <p> {"<code>200</code>"}</p>
                      <p> {"<message>success</message>"}</p>
                      <p> {"<payout>0</payout>"}</p>
                      <p> {"<responseType>NOBID</responseType>"}</p>
                      <p>{"</PingResponse>"}</p>
                    </code>
                  </pre>

                  <h3 id="xml-denied">XML - Denied</h3>
                  <pre>
                    <code>
                      <p>{'<?xml version="1.0" encoding="utf-8"?> '}</p>
                      <p>{"<PingResponse>"}</p>
                      <p> {"<code>200</code>"}</p>
                      <p> {"<message>Access Denied Error: No buyer campaigns found for the vendor campaign 565525d2-0bd2-434a-9eeb-feba793b4d4b</message>"}</p>
                      <p>{"</PingResponse>"}</p>
                    </code>
                  </pre>

                  <h2 id="post-responses">Post Responses</h2>

                  <h3 id="json-success-2">JSON - Success</h3>

                  <pre>
                    <code>{JSON.stringify(data6, null, 2)}</code>
                  </pre>

                  {/* <h3 id="json-re-ping">JSON - Re-ping</h3>

                  <pre>
                    <code>{JSON.stringify(data7, null, 2)}</code>
                  </pre> */}

                  <h3 id="json-denied-2">JSON - Denied</h3>

                  <pre>
                    <code>{JSON.stringify(data9, null, 2)}</code>
                  </pre>

                  <h3 id="xml-success-2">XML - Success</h3>

                  <pre>
                    <code>
                      <p>{"<PostResponse>"}</p>
                      <p> {"<code>200</code>"}</p>
                      <p> {"<message>success</message>"}</p>
                      <p> {"<responseType>POST Success</responseType>"}</p>
                      <p>{"<payout>18</payout>"}</p>
                      <p>{"</PostResponse>"}</p>
                    </code>
                  </pre>

                  {/* <h3 id="xml-re-ping">XML - Re-ping</h3>

                  <pre>
                    <code>
                      <p>{"<PostResponse>"}</p>
                      <p> {"<Status>re-ping</Status>"}</p>
                      <p>{"<PostResponse>"}</p>
                    </code>
                  </pre> */}

                  <h3 id="xml-denied-2">XML - Denied</h3>

                  <pre>
                    <code>
                      <p>{'<?xml version="1.0" encoding="utf-8"?>'}</p>
                      <p>{"<PostResponse>"}</p>
                      <p> {"<code>500</code>"}</p>
                      <p> {"<message>Error: Ping for _leadid '65be09aa-a010-4cb6-b68f-a8326026eeb' was not found</message>"}</p>
                    
                      <p>{"</PostResponse>"}</p>
                    </code>
                  </pre>

                  <h2 id="appendix-field-values">Appendix - Field Values</h2>

                  <h3 id="medical-condition">Medical Condition</h3>

                  <ul>
                    <li>None</li>
                    <li>AIDS / HIV</li>
                    <li>Alzheimer’s Disease</li>
                    <li>Asthma</li>
                    <li>Cancer</li>
                    <li>Cholesterol</li>
                    <li>Depression</li>
                    <li>Diabetes</li>
                    <li>Heart Disease</li>
                    <li>High Blood Pressure</li>
                    <li>Kidney Disease</li>
                    <li>Liver Disease</li>
                    <li>Mental Illness</li>
                    <li>Pulmonary Disease</li>
                    <li>Stroke</li>
                    <li>Ulcer</li>
                    <li>Vascular Disease</li>
                    <li>Other</li>
                  </ul>

                  <h3 id="qualifying-life-condition">
                    Qualifying Life Condition
                  </h3>

                  <ul>
                    <li>Married Or Divorced</li>
                    <li>Had Baby</li>
                    <li>Family Member Died</li>
                    <li>Moved To Another State</li>
                    <li>Lost My Job</li>
                    <li>Started New Job</li>
                    <li>Lost Health Insurance Coverage</li>
                  </ul>
                </div>
              ) : (
                ""
              )
              // <h2 className='no-record'>Sorry No Record Found !!</h2>
            }
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
